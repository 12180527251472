<template>
    <user-dashboard-layout>
        <v-row v-if="getOrders">
            <v-col cols="12">
                <v-card>
                    <v-card-title>{{ $t('general.number-of-orders') }}</v-card-title>
                    <v-sparkline
                        :labels="labelsGraph"
                        :value="valuesGraph"
                        :gradient="gradient"
                        smooth="1"
                        stroke-linecap="round"
                        line-width="2"
                        padding="16"
                        auto-draw
                    ></v-sparkline>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <loading-component/>
        </v-row>
        <v-row v-if="getOrders">
            <v-col cols="12">
                <v-card>
                    <v-card-title>{{ $t('general.last-10-orders') }}</v-card-title>
                    <v-data-table
                        :items="getData"
                        :headers="headers"
                        hide-default-footer
                    >
                        <template v-slot:header="{ props }">
                            <thead>
                            <tr>
                                <th v-for="header in props.headers" :key="header.text">
                                    {{ $t('general.' + header.text.toLowerCase()) }}
                                </th>
                            </tr>
                            </thead>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <loading-component/>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../layouts/UserDashboardLayout";
import LoadingComponent from "../../components/ui/loading-component";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "dashboard-index",
    components: {LoadingComponent, UserDashboardLayout},
    data() {
        return {
            guard_table: [
                'id',
                'created_at'
            ],
            gradient: ['#1E5CBA', '#88BA1E'],
            value_for_sparkline: 0,
            labelsGraph: []
        }
    },
    mounted() {
        this.loadOrders()
    },
    computed: {
        valuesGraph: function () {
            let val_for_labels_graph = []
            let val_for_val_graph = []
            if (this.getOrders && this.getOrders.data && this.getOrders.data[0]) {
                let dates = []
                this.getOrders.data.forEach(item => {
                    if (item.date) {
                        dates.push(item.date)
                    }
                })

                let date_qty = []
                dates.forEach(item => {
                    let i = 0
                    dates.forEach(item_inner => {
                        if (item === item_inner) {
                            i++
                        }
                    })

                    let j = false
                    if (date_qty.length > 0) {
                        for (let val of date_qty) {
                            if (val.date === item) {
                                j = true
                            }
                        }
                        if (!j) date_qty.push({date: item, qty: i})
                        j = false
                    } else {
                        date_qty.push({date: item, qty: i})
                    }
                    i = 0
                })

                date_qty.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date)
                })

                date_qty.forEach(item => {
                    val_for_labels_graph.push(item.date)
                    val_for_val_graph.push(item.qty)
                })

                let next_item_date = new Date(Date.parse(date_qty[date_qty.length - 1].date))
                let next_date = next_item_date.getDate() + 1
                val_for_labels_graph.push(next_item_date.getFullYear() + '-' + this.addZeroz(next_item_date.getMonth() + 1) + '-' + next_date)

                let previous_item_date = new Date(Date.parse(date_qty[0].date))
                let previous_date = previous_item_date.getDate() - 1
                val_for_labels_graph.unshift(previous_item_date.getFullYear() + '-' + this.addZeroz(previous_item_date.getMonth() + 1) + '-' + previous_date)

                val_for_val_graph.push(0)
                val_for_val_graph.unshift(0)

                this.calcLabelsGraph(val_for_labels_graph.sort((a, b) => new Date(a) - new Date(b)))
                return val_for_val_graph
            }
            this.calcLabelsGraph(val_for_labels_graph)
            return val_for_val_graph
        },
        headers: function () {
            let headers = []
            if (this.getOrders && this.getOrders.data && this.getOrders.data[0]) {
                let keys = Object.keys(this.getOrders.data[0])
                keys.map(el => {
                    if (!this.guard_table.includes(el)) {
                        if (el !== 'details' && el !== 'delivery_type' && el !== 'token' && el !== 'delivery_cost' && el !== 'delivery') {
                            headers.push({
                                text: el,
                                value: el,
                            })
                        }
                    }
                })
            }
            return headers
        },
        getData: function () {
            return this.getOrders.data.map((item) => {
                (item.delivery?.address) ? item.delivery = item.delivery.address : item.delivery = ''
                delete item.pickup_delivery_vending;
                return item;
            });
        },
        ...mapGetters(["getOrders"]),
    },
    methods: {
        ...mapActions(["loadOrders"]),
        calcLabelsGraph(arr) {
            if (arr.length > 0) {
                this.labelsGraph = arr
            }
        },
        addZeroz(number) {
            return ('0' + number).slice(-2);
        }
    }
}
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}

.primary--text g text {
    font-size: 2px;
}
</style>
