<template>
    <v-col>
        <v-row class="d-flex justify-center">
            <v-icon
                large
                class="spinner-icon"
                color="blue-grey darken-2"
            >
                mdi-autorenew
            </v-icon>
        </v-row>
    </v-col>
</template>

<script>
    export default {
        name: "loading-component"
    }
</script>

<style scoped>
    .spinner-icon {
        transform: rotate(360deg);
        animation: linear spin 5s infinite;
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
